<template>
  <div class="benchmark-manager__matches-wrapper">
    <!--------------------------------------------
    | 
    | TOOLBAR
    |
    --------------------------------------------->
    <div class="table__toolbar" v-if="!noMatches">
      <div class="select-wrapper">
        <label class="sr-only" for="status-select">
          <VisibleText>select questions with status</VisibleText>
        </label>
        <select
          class="select table__toolbar-select"
          name="select-status"
          id="status-select"
          ref="status-select"
          @change="selectMatches($event)"
        >
          <option value="" disabled="true" selected>Select</option>
          <option v-for="status in statusOptions" :key="status" :value="status">
            {{ status }}
          </option>
        </select>
      </div>
      <button class="table__toolbar-btn approve" @click="approveSelected">
        <SvgIconDecorative icon="check" />
        <VisibleText>approve</VisibleText>
      </button>
      <button class="table__toolbar-btn reject" @click="rejectSelected">
        <SvgIconDecorative icon="remove" />
        <VisibleText>reject</VisibleText>
      </button>
    </div>

    <Table
      class="benchmark-manager__matches-table"
      :row-data="benchmarkMatches"
      :column-headers="columnHeaders"
      v-if="!noMatches"
    >
      <template v-slot="{ row, index }">
        <td data-label="User">
          <label
            :for="`${row.benchmarkId}`"
            class="form-label form-checkbox-label"
          >
            <input
              type="checkbox"
              :id="`${row.benchmarkId}`"
              :name="row.benchmarkId"
              :value="row.benchmarkId"
              v-model="selectedMatches"
              number
            />
            {{ row.owner.user }}
            <span class="table__secondary-data">
              {{ row.owner.organization }}
            </span>
          </label>
        </td>
        <td data-label="User Question Code">
          {{ row.userQuestionTitle }}
        </td>
        <td data-label="User Question Wording">
          {{ row.userQuestionText }}
        </td>
        <td data-label="Status">
          <span
            :class="[
              'status',
              row.status,
              { approved: row.status === 'auto approved' }
            ]"
          >
            {{ row.status }}
          </span>
        </td>
        <td data-label="Action" class="matches-table__actions">
          <button
            class="matches-table__action"
            @click="approve(row.benchmarkId, index)"
          >
            <VisibleText>Approve</VisibleText>
          </button>
          |
          <button
            class="matches-table__action"
            @click="reject(row.benchmarkId, index)"
          >
            <VisibleText>Reject</VisibleText>
          </button>
        </td>
      </template>
    </Table>
    <div v-else>
      <VisibleText>no matches</VisibleText>
    </div>
    <Spinner
      :is-loading="loading"
      :component-style="true"
      message="updating status, please wait"
      complete-message="update complete"
    />
  </div>
</template>

<script>
// Componenets
import Table from "@/components/UI/Table.vue"
import Spinner from "@/components/UI/Spinner.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Services
import BenchmarkingService from "@/services/benchmarkingService.js"
import DatasetsService from "@/services/datasetsService.js"
import ProfilesService from "@/services/profilesService.js"

export default {
  name: "BenchmarkManagerMatches",
  components: {
    Table,
    Spinner,
    SvgIconDecorative
  },
  props: {
    caption: {
      default: () => "Benchmark Matches",
      type: String
    },
    globalQuestionId: {
      default: null,
      type: String,
      required: true
    }
  },
  data() {
    return {
      BENCHMARKING_SERVICE: new BenchmarkingService(this.$pigeonline),
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      PROFILES_SERVICE: new ProfilesService(this.$pigeonline),
      columnHeaders: [
        "User",
        "User Question Code",
        "User Question Wording",
        "Status",
        "Action"
      ],
      benchmarkMatches: [],
      noMatches: false,
      statusOptions: ["none", "all", "approved", "pending", "rejected"],
      selectedMatches: [],
      loading: false
    }
  },
  created() {
    this.loadData().then(({ benchmarks, clientQuestions, users }) => {
      if (benchmarks.length > 0)
        this.formatBenchmarkMatches(benchmarks, clientQuestions, users)
      else this.noMatches = true
    })
  },
  methods: {
    async loadData() {
      const [benchmarks, clientQuestions, users] = await Promise.all([
        this.fetchBenchmarks(),
        this.fetchClientQuestions(),
        this.fetchUsers()
      ])
      return { benchmarks, clientQuestions, users }
    },
    async approveSelected() {
      if (this.selectMatches.length === 0) return
      this.loading = true
      await Promise.all(
        this.selectedMatches.map(async id => {
          let index = this.benchmarkMatches.findIndex(m => m.benchmarkId === id)
          await this.approve(id, index)
        })
      )
      this.loading = false
    },
    async rejectSelected() {
      if (this.selectMatches.length === 0) return
      this.loading = true
      await Promise.all(
        this.selectedMatches.map(async id => {
          let index = this.benchmarkMatches.findIndex(m => m.benchmarkId === id)
          await this.reject(id, index)
        })
      )
      this.loading = false
    },
    /**
     * Fetching and saving benchmarks
     */
    async fetchBenchmarks() {
      try {
        const response = await this.BENCHMARKING_SERVICE.benchmarking({
          global_question_id: this.globalQuestionId
        })
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:fetchBenchmarks " + e.message)
      }
    },
    /**
     * Fetching and saving client questions
     */
    async fetchClientQuestions() {
      try {
        // backwards compatibility with client question schema v1
        const response1 = await this.DATASETS_SERVICE.clientQuestions(null, {
          benchmarked_global_question_id: this.globalQuestionId
        })
        const response2 = await this.DATASETS_SERVICE.clientQuestions(null, {
          "benchmarked_global_question.selected": this.globalQuestionId
        })
        return [...response1, ...response2]
      } catch (e) {
        throw new Error(
          "BenchmarkManagerMatches:fetchClientQuestions " + e.message
        )
      }
    },
    async fetchUsers() {
      try {
        const response = await this.PROFILES_SERVICE.users()
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:fetchUsers " + e.message)
      }
    },
    async approve(benchmarkId, index) {
      if (
        ["approved", "auto approved"].includes(
          this.benchmarkMatches[index].status
        )
      )
        return

      try {
        await this.BENCHMARKING_SERVICE.update(benchmarkId, {
          approved: true
        })
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:approve " + e.message)
      }

      this.benchmarkMatches[index].status = "approved"
      this.$emit("updateGlobalQuestionMatches", "approved")
    },
    async reject(benchmarkId, index) {
      if (this.benchmarkMatches[index].status === "rejected") return

      try {
        await this.BENCHMARKING_SERVICE.update(benchmarkId, {
          auto_approved: false,
          approved: false,
          rejected: true
        })
      } catch (e) {
        throw new Error("BenchmarkManagerMatches:approve " + e.message)
      }

      this.benchmarkMatches[index].status = "rejected"
      this.$emit("updateGlobalQuestionMatches", "rejected")
    },
    formatBenchmarkMatches(benchmarks, clientQuestions, users) {
      const setStatus = (approved, rejected, autoApproved) => {
        if (autoApproved) return "auto approved"
        else if (approved) return "approved"
        else if (rejected) return "rejected"
        else return "pending"
      }

      const setOwner = owner => {
        if (!owner) return { user: "unknown", organization: "" }
        const userDetails = users.find(user => user.id === owner.user)
        return {
          user: userDetails ? userDetails.email : "organization",
          organization: owner.organization || "n/a"
        }
      }

      this.benchmarkMatches = benchmarks.reduce((matches, benchmark) => {
        const cQ = clientQuestions.find(
          c => c._id.$oid === benchmark.client_question_id
        )
        if (!cQ) return matches
        matches.push({
          benchmarkId: benchmark._id.$oid,
          checked: false,
          owner: setOwner(benchmark.owner),
          userQuestionTitle: cQ.question_title,
          userQuestionText: cQ.question_text,
          status: setStatus(
            benchmark.approved,
            benchmark.rejected,
            benchmark.auto_approved
          )
        })
        return matches
      }, [])
    },
    selectMatches($event) {
      let type = $event.target.value
      if (["approved", "auto approved", "rejected", "pending"].includes(type)) {
        this.selectedMatches = this.benchmarkMatches
          .filter(match => match.status === type)
          .map(m => m.benchmarkId)
      } else if (type === "all") {
        this.selectedMatches = this.benchmarkMatches.map(
          match => match.benchmarkId
        )
      } else {
        this.selectedMatches = []
      }
    }
  }
}
</script>
