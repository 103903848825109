var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"benchmark-manager__matches-wrapper"},[(!_vm.noMatches)?_c('div',{staticClass:"table__toolbar"},[_c('div',{staticClass:"select-wrapper"},[_c('label',{staticClass:"sr-only",attrs:{"for":"status-select"}},[_c('VisibleText',[_vm._v("select questions with status")])],1),_c('select',{ref:"status-select",staticClass:"select table__toolbar-select",attrs:{"name":"select-status","id":"status-select"},on:{"change":function($event){return _vm.selectMatches($event)}}},[_c('option',{attrs:{"value":"","disabled":"true","selected":""}},[_vm._v("Select")]),_vm._l((_vm.statusOptions),function(status){return _c('option',{key:status,domProps:{"value":status}},[_vm._v(" "+_vm._s(status)+" ")])})],2)]),_c('button',{staticClass:"table__toolbar-btn approve",on:{"click":_vm.approveSelected}},[_c('SvgIconDecorative',{attrs:{"icon":"check"}}),_c('VisibleText',[_vm._v("approve")])],1),_c('button',{staticClass:"table__toolbar-btn reject",on:{"click":_vm.rejectSelected}},[_c('SvgIconDecorative',{attrs:{"icon":"remove"}}),_c('VisibleText',[_vm._v("reject")])],1)]):_vm._e(),(!_vm.noMatches)?_c('Table',{staticClass:"benchmark-manager__matches-table",attrs:{"row-data":_vm.benchmarkMatches,"column-headers":_vm.columnHeaders},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',{attrs:{"data-label":"User"}},[_c('label',{staticClass:"form-label form-checkbox-label",attrs:{"for":("" + (row.benchmarkId))}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedMatches),expression:"selectedMatches"}],attrs:{"type":"checkbox","id":("" + (row.benchmarkId)),"name":row.benchmarkId,"number":""},domProps:{"value":row.benchmarkId,"checked":Array.isArray(_vm.selectedMatches)?_vm._i(_vm.selectedMatches,row.benchmarkId)>-1:(_vm.selectedMatches)},on:{"change":function($event){var $$a=_vm.selectedMatches,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=row.benchmarkId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedMatches=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedMatches=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedMatches=$$c}}}}),_vm._v(" "+_vm._s(row.owner.user)+" "),_c('span',{staticClass:"table__secondary-data"},[_vm._v(" "+_vm._s(row.owner.organization)+" ")])])]),_c('td',{attrs:{"data-label":"User Question Code"}},[_vm._v(" "+_vm._s(row.userQuestionTitle)+" ")]),_c('td',{attrs:{"data-label":"User Question Wording"}},[_vm._v(" "+_vm._s(row.userQuestionText)+" ")]),_c('td',{attrs:{"data-label":"Status"}},[_c('span',{class:[
            'status',
            row.status,
            { approved: row.status === 'auto approved' }
          ]},[_vm._v(" "+_vm._s(row.status)+" ")])]),_c('td',{staticClass:"matches-table__actions",attrs:{"data-label":"Action"}},[_c('button',{staticClass:"matches-table__action",on:{"click":function($event){return _vm.approve(row.benchmarkId, index)}}},[_c('VisibleText',[_vm._v("Approve")])],1),_vm._v(" | "),_c('button',{staticClass:"matches-table__action",on:{"click":function($event){return _vm.reject(row.benchmarkId, index)}}},[_c('VisibleText',[_vm._v("Reject")])],1)])]}}],null,false,3374690617)}):_c('div',[_c('VisibleText',[_vm._v("no matches")])],1),_c('Spinner',{attrs:{"is-loading":_vm.loading,"component-style":true,"message":"updating status, please wait","complete-message":"update complete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }